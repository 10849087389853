<template>
  <div class="container" id="container">
    <!-- <div class="top-banner">
      <div class="banner-img">
        <img class="banner" src="../../../assets/images/jobs/banner.png" alt="">
      </div>
    </div> -->

    <div class="main-page">

      <p style="font-size: 14px;font-weight: bold;padding: 1em;color: #ee0a24;background: #efefef;" v-if="interview_choose_id && interview_info && interview_info.desc">{{ interview_info.desc }}</p>

      <van-tabs class="interview-list" v-model="active">
        <van-tab v-for="(item, i) in interview" :key="i">
          <template #title>
            <p>{{ item.view_date }}</p>
            <p>星期{{ item.view_date_w }}</p>
          </template>
          <div class="interview-data-list">
            <a v-for="(time, t) in item.item_list" :key="t" href="JavaScript:;"
              :class="{ 'disable': time.interview_status == 0,'is-interview': interview_choose_id == time.interview_choose_id }" @click="tonIterview(item, time)">
              <p>{{ time.start_time }} ~ {{ time.end_time }}</p>
              <p>{{ interview_choose_id == time.interview_choose_id ? '已预约': time.interview_status == 0 ? '可预约' : '不可约' }}</p>
            </a>
          </div>

        </van-tab>
      </van-tabs>
    </div>



  </div>
</template>

<script>

import { mapState } from "vuex";
import { Dialog } from 'vant';

export default {
  name: "JobList",

  data() {
    return {
      errorText: "网络开小差了，点击重新加载",
      jobList: [],
      searchInput: "",
      searchKeyword: "",
      // 工作地点
      locationOptions: [],
      workTypeOptions: [],
      locationSelected: "",
      positionSelected: "",
      jobPopupVisible: false,
      listPage: 0,
      loading: false,
      finished: false,
      error: false,
      interview: [],
      active: 0,
      interview_choose_id:null,
      interview_info:null
    };
  },
  computed: {
    ...mapState("dic", ["workTypeList"]),
    // 职位类型
    showEmpty() {
      return !this.error && !this.loading && this.jobList.length === 0;
    },
    finishedText() {
      return this.jobList.length === 0 ? "" : "— 到底咯！—";
    },
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler: function (newPath, oldPath) {
        if (newPath !== oldPath) {
          const container = document.getElementById("container");
          if (container) {
            container.scrollTop = 0;
          }
          this.init()
        }
      },
    },
  },
  created() {

  },
  mounted() {
    this.init()
  },
  methods: {
    tonIterview(item, time) {
      this.loading = true;
      const params = {
        job_id: this.$route.query.job_id,
        interview_choose_id: time.interview_choose_id,
      };

      if(time.interview_status != 0){
        return
      }

      Dialog.confirm({
        message: `确认预约${item.view_date}星期${item.view_date_w}${time.start_time}-${time.end_time}的时间段面试吗？`,
      })
        .then(() => {
          this.$api
            .GET_JOB_INTERVIEW_DO(params)
            .then(() => {
              Dialog.alert({
                message: '预约成功！',
                confirmButtonText: '返回首页'
              }).then(() => {
                this.$router.push({
                  name: "Index"
                });
              });
            })
        })
        .catch(() => {
          this.loading = false;
        });



    },
    init() {
      this.$api.GET_JOB_INTERVIEW_LIST({ job_id: this.$route.query.job_id }).then(res => {
        this.interview = res
        this.$api.GET_JOB_INTERVIEW_INFO({ job_id: this.$route.query.job_id}).then(res2 => {
          this.interview_info = res2
          this.interview_choose_id = res2.interview_choose_id
          this.interview.forEach((item,i) =>{
            if(item.view_date == res2.view_date ){
              this.active = i
            }
          })
        })
      })

    }

  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  background: white;
  padding-bottom: 75px;
}

.top-banner {
  .banner-video {
    width: 100%;
    z-index: 10;
    overflow: hidden;

    .video {
      overflow: hidden;
      // border-radius: 15px 15px 0 0;
    }
  }

  .banner-img img {
    width: 100%;
  }
}

::v-deep {
  .van-tab {
    line-height: 1;
  }

  .van-tab__text {
    display: block;

    p {
      font-size: 14px;
      margin: 9px 0;
    }
  }

  .van-tabs__line {
    height: 2px;
  }

  .van-tabs--line .van-tabs__wrap {
    height: 80px;
  }
}

.interview-data-list {
  display: flex;
  flex-wrap: wrap;

  a {
    width: calc(50% - 10px);
    color: #333;
    text-align: center;
    background: #ddd;
    margin: 5px;
    font-size: 14px;
    padding: 10px 0;
    font-weight:bold;
    p {
      margin: 5px;
    }
  }

  a.disable {
    background: #efefef;
    color:#00AB44;
    font-weight:bold;

  }

  a.is-interview{
    background: #efefef;
    color: #ee0924;
    font-weight:bold;
  }
}

@media (min-width: 992px) {
  .container {
    height: 100%;
    padding-bottom: 75px;
    overflow-y: auto;

  }

  .main-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .top-banner {
    .banner-video {
      width: 100%;
    }

    .banner-img img {
      width: 100%;
    }
  }

  .interview-data-list {
    display: flex;
    flex-wrap: wrap;

    a {
      width: calc(20% - 10px);
      color: #333;
      text-align: center;
      background: #ddd;
      margin: 5px;
      font-size: 14px;
      padding: 10px 0;

      p {
        margin: 5px;
      }
    }

    a.disable {
      background: #efefef;
    }
  }

  ::v-deep {
    .van-tab {
      line-height: 1;
    }

    .van-tab__text {
      display: block;

      p {
        font-size: 14px;
        margin: 9px 0;
      }
    }

    .van-tabs__line {
      height: 2px;
    }

    .van-tabs--line .van-tabs__wrap {
      height: 80px;
    }
  }

}
</style>

<style scoped lang="scss">
::v-deep .van-loading {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

@media (min-width: 992px) {
  ::v-deep .van-loading {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
}
</style>

